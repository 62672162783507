
import {computed, defineComponent, ref} from "vue";
import {useActionLogStore} from "@/store/actionlog.store";
import {PinaFilterObjects} from "@/core/composite/composite";
import ActionLogCards from "@/views/entity/ActionLogCards.vue";
import InfoBox from "@/components/base/InfoBox.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";

export default defineComponent({
  name: "ActionLogs",
  components: {HelpEmbed, InfoBox, ActionLogCards},
  setup() {
    const objectFilter = ref({isCompany: true})
    const actionLogStore = useActionLogStore();
    const page = computed(() => actionLogStore.page)
    return {
      page,
      ...PinaFilterObjects(actionLogStore, objectFilter.value, ['user'], '')
    }
  }
})
